import BSCSwapAgentABI from "./BSCSwapAgent_ABI.json";
import EthSwapAgentABI from "./EthSwapAgent_ABI.json";
import IBEP20ABI from "./IBEP20_ABI.json";
export const fiero_RPC = "https://devnet-rpc1.tanledger.com/";
export const nodeApi = "https://xtring.network/api"
const base = "https://stgapi-bridge.tarality.io";
// const base ="http://172.16.16.27:1970"
// const base ="http://172.16.16.26:1970"

export const apiURL = `${base}/api/v1/block`;
export const ETHswap = "0x08b7A2a5B3864D74224583D2d01882ae7956277d"; //fiero_swap_address
export const EthereumChainId = 11155111;
export const BinanceChainId = 97;
export const AvalancheChainId = 43114;
export const MaticChainId = 137;
export const FieroChainId = 4442;
export const wFiero = "0x2e1Ad83B4F2AE3EFD169E1d161dD818C0b73AB15";

//RPC details of environment networks
export const getRPCDetails = (chainId) => {
  switch (parseInt(chainId)) {
    case EthereumChainId:
      return {
        // url: "https://mainnet.infura.io/v3/",
        url:'https://sepolia.infura.io/v3/858cc6c80fbc4c468ba24ee552a327c2',
        // url:'https://ethereum-sepolia-rpc.publicnode.com',
        block_url:"https://sepolia.etherscan.io/",
        // block_url: "https://etherscan.io",
        // chainId_hex: "0x1",
        chainId_hex:"0xaa36a7",
        name: "ETH",
        symbol: "ETH",
        network_name: "Ethereum Mainnet",
      };
    case BinanceChainId:
      return {
        url: "https://data-seed-prebsc-1-s1.bnbchain.org:8545",
        block_url: "https://testnet.bscscan.com",
        chainId_hex: "0x61",
        name: "BNB",
        symbol: "BNB",
        network_name: "Binance Testnet",
      };
    case AvalancheChainId:
      return {
        url: "https://api.avax.network/ext/bc/C/rpc",
        block_url: "https://snowtrace.io/",
        chainId_hex: "0xA86A",
        name: "AVAX",
        symbol: "AVAX",
        network_name: "Avalanche Mainnet",
      };
    case MaticChainId:
      return {
        url: "https://polygon-rpc.com",
        block_url: "https://polygonscan.com/",
        chainId_hex: "0x89",
        name: "MATIC",
        symbol: "MATIC",
        network_name: "Polygon Mainnet",
      };
    case FieroChainId:
      return {
        url: "https://devnet-rpc1.tanledger.com",
        block_url: "https://devnet.taralscan.com/",
        chainId_hex: "0x115A",
        name: "TAN",
        symbol: "TAN",
        network_name: "TAN Devnet",
      };
  }
};

//Token swap agent addresses
export const getCurrentSwapAddress = (chainId) => {
  switch (parseInt(chainId)) {
    case EthereumChainId:
      return "0x8525e028BE502184D0ED5f22706c340c2d0e1f88";
    case BinanceChainId:
      return "0x4797aD493ad870444c54b6378174E471166566c6";
    case AvalancheChainId:
      return "0xBa0e5551c517B43c24b9dff149C31A9Af7bA51B9";
    case MaticChainId:
      return "0xc46C64E21E7E06aDde4a2919Ac984D42D87e523e";
  }
};

//Coin swap agent addresses
export const getCoinSwapAddress = (chainId) => {
  switch (parseInt(chainId)) {
    case EthereumChainId:
      return "0xE212B17bA0c480a8b124Cf739D31AC33c60B2b0D";
    case BinanceChainId:
      return "0x2E103D76E938A6578bC31B98c582e113D8dc5F97";
    case AvalancheChainId:
      return "0x57F32d38fd099E1752e5D3ad52ea10a56eD5e502";
    case MaticChainId:
      return "0xc46C64E21E7E06aDde4a2919Ac984D42D87e523e";
  }
};

//Token Basic Details
export const getCoinBasicDetails = (chainId) => {
  switch (parseInt(chainId)) {
    case EthereumChainId:
      return {
        name: "Ethereum Mainnet",
        symbol: "ETH",
        image: "/images/coins/eth.png",
        token: "Ethereum ERC20",
        chain: EthereumChainId,
      };
    case BinanceChainId:
      return {
        name: "Binance Mainnet",
        symbol: "BNB",
        image: "/images/coins/bnb.png",
        token: "Binance BEP20",
        chain: BinanceChainId,
      };
    case AvalancheChainId:
      return {
        name: "Avalanche Mainnet",
        symbol: "AVAX",
        image: "/images/coins/avax-logo.png",
        token: "Avalanche ERC20",
        chain: AvalancheChainId,
      };
    case MaticChainId:
      return {
        name: "Polygon Mainnet",
        symbol: "MATIC",
        image: "/images/coins/polygon-matic-logo.png",
        token: "Polygon ERC20",
        chain: MaticChainId,
      };
    case FieroChainId:
      return {
        name: "TAN Mainnet",
        symbol: "TAN",
        image: "/images/coins/common_logo.png",
        token: "FIERES ERC20",
        chain: FieroChainId,
      };
  }
};

export const NetworkDetailsList = [
  {
    name: "Ethereum Mainnet",
    symbol: "ETH",
    image: "/images/coins/eth.png",
    token: "Ethereum ERC20",
    chain: EthereumChainId,
  },
  // {
  //   name: "Polygon Mainnet",
  //   symbol: "MATIC",
  //   image: "/images/coins/polygon-matic-logo.png",
  //   token: "Polygon ERC20",
  //   chain: MaticChainId,
  // },
  // {
  //   name: "Avalanche Mainnet",
  //   symbol: "AVAX",
  //   image: "/images/coins/avax-logo.png",
  //   token: "Avalanche ERC20",
  //   chain: AvalancheChainId,
  // },
  {
    name: "Binance Mainnet",
    symbol: "BNB",
    image: "/images/coins/bnb.png",
    token: "Binance BEP20",
    chain: BinanceChainId,
  },
];

export const BSCSwapAgentABIData = BSCSwapAgentABI;
export const EthSwapAgentABIData = EthSwapAgentABI;
export const IBEP20ABIData = IBEP20ABI;
export function generateUniqueAvatar(value) {
  return `https://avatars.dicebear.com/api/identicon/${value}.svg`;
}
