import React, { useState, useEffect } from "react";
import {
  Box,
  FormHelperText,
  Typography,
  TextField,
  Container,
  Button,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import * as yep from "yup";
import axios from "axios";
import { apiURL } from "../../Utils";
import ButtonCircularProgress from "../../components/ButtonCircularProgress";
const useStyles = makeStyles((theme) => ({
  connect: {
    background:
      "linear-gradient(93.14deg, rgb(255, 176, 0) -20.75%, rgb(255, 86, 77) 11.84%, #ffb000 53.76%, #ffb000 102.96%)",
    filter: "drop-shadow(0px 13px 27px rgba(0, 0, 0, 0.25))",
    color: "#fff",
    borderRadius: "50px",
    whiteSpace: "pre",
    fontSize: "14px",
    lineHeight: " 21px",
    padding: "12px 35px",
    fontWeight: "500",
    // border:"1px solid transparent",

    "&:hover": {
      color: "#ffffff",
      boxShadow:
        "0 1px 0 0 #fe5aeb, 0 -1px 0 0 #f4a91b, 1px 0 0 0 #fe5aeb, -1px 0 0 0 rgb(254 90 235), 1px -1px 0 0 #f4a91b, -1px 1px 0 0 rgb(254 90 235), 1px 1px 0 0 rgb(254 90 235), -1px -1px 0 0 rgb(244 168 26)",
      backgroundColor: "transparent",
      background: "transparent",
    },
  },
  gradientBorder: {
    borderRadius: "15px",
    padding: "2px!important",
  },
  mainbridge: {
    padding: "30px 20px 30px",
    background: " #0c0508",
    borderRadius: "15px",
    "& .MuiOutlinedInput-root": {
      color: "white",
      position: "relative",
      background: "#101010",
      borderRadius: "10px",
    },
    "& .MuiInputBase-input:-webkit-autofill": {
      animationName: "mui-auto-fill",
      WebkitBoxShadow: "0 0 0 100px #171717 inset !important",
      animationDuration: "5000s",
      WebkitBackgroundClip: "text !important",
      WebkitTextFillColor: "rgba(255, 255, 255, 0.6)!important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ff0098",
    },
    "& .MuiInputBase-input": {
      fontWeight: "300",
      fontSize: "14px",
      color: "rgba(255, 255, 255, 0.6)!important",
    },
    "& p": {
      color: "#ffffffdb",
      fontWeight: "300",
      marginBottom: "7px",
      fontSize: "13px",
    },
  },
  heading: {
    "& h4": {
      //   fontFamily: "Good Times W00 Bold",
      fontSize: "30px",
      //   fontWeight: "700",
      textAlign: "left",
      textTransform: "uppercase",
      marginBottom: "30px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
      },
    },
  },
  FormInputField: {},
}));
export default function Support() {
  const classes = useStyles();
  const [isSending, setIsSending] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const formInitialSchema = {
    name: "",
    email: "",
    subject: "",
    additionalDetails: "",
  };
  const formValidationSchema = yep.object().shape({
    email: yep
      .string()
      .email("Please enter a valid email address.")
      .max(256, "Should not exceeds 256 characters.")
      .required("Please enter your email."),
    name: yep
      .string()
      .required("Please enter name.")
      .matches(
        /^[a-zA-Z]+(([',. -][a-zA-Z])?[a-zA-Z]*)*$/g,
        "Please enter a valid name."
      )
      .min(3, "Please enter atleast 3 characters.")
      .max(256, "You can enter only 256 characters."),
    subject: yep
      .string()
      .required("Please enter a subject.")
      .min(10, "Please enter atleast 10 charachters.")
      .max(120, "You can not enter more than 120 charachters."),
    additionalDetails: yep
      .string()
      .required("Please provide some details.")
      .min(10, "Please enter atleast 10 charachters.")
      .max(320, "You can not enter more than 320 charachters."),
  });
  const contactUsFormHandler = async (values) => {
    try {
      setSuccessMsg("");
      setIsSending(true);
      const response = await axios({
        method: "POST",
        url: `${apiURL}/contactUs`,
        data: {
          name: values.name,
          email: values.email,
          title: values.subject,
          description: values.additionalDetails,
          subject: values.subject,
          message: values.additionalDetails,
        },
      });
      if (response.data.responseCode === 200) {
        setIsSending(false);
        setSuccessMsg("Your message has been sent to admin successfully.");
      } else {
        setIsSending(false);
      }
    } catch (error) {
      console.log(error);
      setIsSending(false);
    }
  };
  useEffect(() => {
    if (successMsg !== "") {
      setTimeout(() => {
        setSuccessMsg("");
      }, 3000);
    }
  }, [successMsg]);

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.gradientBorder}>
        <Container>
          <Grid Container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box className={classes.mainbridge}>
                <Box className={classes.heading}>
                  <Typography variant="h4">Help And Support</Typography>
                </Box>
                <Box>
                  <Formik
                    initialValues={formInitialSchema}
                    initialStatus={{
                      success: false,
                      successMsg: "",
                    }}
                    validationSchema={formValidationSchema}
                    onSubmit={(values, { resetForm }) => {
                      contactUsFormHandler(values);
                      resetForm();
                    }}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      touched,
                      values,
                      setFieldValue,
                    }) => (
                      <Form className={classes.root}>
                        <Box>
                          <Box>
                            <Typography variant="body2">
                              Name <span style={{ color: "#EB5A2C" }}> *</span>
                            </Typography>
                          </Box>
                          <TextField
                            placeholder="Enter your name"
                            variant="outlined"
                            fullWidth
                            name="name"
                            id="name"
                            value={values.name}
                            error={Boolean(touched.name && errors.name)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className={classes.FormInputField}
                          />
                          <FormHelperText error className={classes.helperText}>
                            {touched.name && errors.name}
                          </FormHelperText>
                        </Box>

                        <Box mt={3}>
                          <Box>
                            <Typography variant="body2">
                              Email <span style={{ color: "#EB5A2C" }}> *</span>
                            </Typography>
                          </Box>
                          <TextField
                            placeholder="Enter your email"
                            variant="outlined"
                            fullWidth
                            name="email"
                            id="email"
                            value={values.email}
                            error={Boolean(touched.email && errors.email)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className={classes.FormInputField}
                          />
                          <FormHelperText error className={classes.helperText}>
                            {touched.email && errors.email}
                          </FormHelperText>
                        </Box>
                        <Box mt={3}>
                          <Box>
                            <Typography variant="body2">
                              Subject{" "}
                              <span style={{ color: "#EB5A2C" }}> *</span>
                            </Typography>
                          </Box>
                          <TextField
                            placeholder="Enter subject"
                            variant="outlined"
                            fullWidth
                            name="subject"
                            id="subject"
                            value={values.subject}
                            error={Boolean(touched.subject && errors.subject)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className={classes.FormInputField}
                          />
                          <FormHelperText error className={classes.helperText}>
                            {touched.subject && errors.subject}
                          </FormHelperText>
                        </Box>
                        <Box mt={3}>
                          <Box>
                            <Typography variant="body2">
                              Addtional Details{" "}
                              <span style={{ color: "#EB5A2C" }}> *</span>
                            </Typography>
                          </Box>
                          <TextField
                            placeholder="Enter details"
                            variant="outlined"
                            multiline
                            rows={5}
                            fullWidth
                            name="additionalDetails"
                            id="additionalDetails"
                            value={values.additionalDetails}
                            error={Boolean(
                              touched.additionalDetails &&
                                errors.additionalDetails
                            )}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className={classes.FormInputField}
                          />
                          <FormHelperText error className={classes.helperText}>
                            {touched.additionalDetails &&
                              errors.additionalDetails}
                          </FormHelperText>
                        </Box>
                        <Box mt={2}>
                          {successMsg !== "" && (
                            <FormHelperText style={{ color: "#4ecb38" }}>
                              {successMsg}
                            </FormHelperText>
                          )}

                          <Button
                            type="submit"
                            className={classes.connect}
                            style={{ background: "#0e0d0e" }}
                            disabled={isSending}
                          >
                            {isSending ? (
                              <>
                                Sending...
                                <ButtonCircularProgress />
                              </>
                            ) : (
                              "Send message"
                            )}
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={6}></Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
