import React, { useState, useEffect } from "react";
import "./App.css";
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";
import Header from "./components/Header";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import useAccount from "./hooks/useAccount";
import useChainId from "./hooks/useChainId";
import { connectToWallet } from "./hooks/operations";
import { BinanceChainId, FieroChainId, getRPCDetails, apiURL } from "./Utils";
import NavBar from "./layout/NavBar/NavBar";
import Support from "./pages/Home/Support";
import CustomTaost from "./components/CustomToast";
import { Toaster } from "react-hot-toast";
import axios from "axios";
import CookieConsent from "react-cookie-consent";
import { Typography } from "@material-ui/core";
const theme = createMuiTheme({
  typography: {
    fontFamily: "'Sora', sans-serif",
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "capitalize",
        color: "#fff",
      },
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    height: "100vh",
    display: "flex",
    overflow: "hidden",
    position: "relative",
    backgroundColor: "#fff",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    position: "relative",
    backgroundColor: "#000",
    paddingTop: 14,
    // minHeight: "calc(100vh - 75px)",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
    "@media (max-width:767px)": {
      paddingTop: "25px !important",
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    padding: "91px 53px 0px",
    overflow: "hidden",
    position: "relative",
    zIndex: "0",
    [theme.breakpoints.down("md")]: {
      padding: "30px 10px 10px ",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "72px 0 27px",
    },
    "& .colorShade": {
      right: "0px",
      width: "400px",
      bottom: "0px",
      filter: "blur(73px)",
      height: "400px",
      opacity: "0.1",
      zIndex: "1",
      position: "absolute",
      transform: "rotate(7deg)",
      background:
        "radial-gradient(62.66% 62.66% at 50% 50%, #e6e5e800 0%, rgb(0 0 0) 100%), #ffffff85",
      borderRadius: "500px",
    },
  },
}));
function App() {
  const changeAccout = useAccount();
  const classes = useStyles();
  const chainId = useChainId();
  const [accountAdd, setAccount] = useState(changeAccout);
  const [selectedChain, setSelectedChain] = useState(BinanceChainId);
  const [isSwaped, setIsSwaped] = useState(false);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [tabView, setTabView] = useState("Bridge");
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const [errMsg, setErrMsg] = useState({
    text: "",
    color: "",
  });

  const { mobileView, drawerOpen } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1280
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);
  const connect = async () => {
    if (window.ethereum) {
      const res = await connectToWallet();
      setAccount(res);
    } else {
      // alert("Please Install Metamask");
    }
  };
  const swichNetworkHandler = async (activeChain) => {
    const NetworkDetails = getRPCDetails(activeChain);
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + activeChain.toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);
      setSelectedChain(parseInt(chainId));
      if (chainId == FieroChainId) {
        setIsSwaped(false);
        setSelectedChain(
          window.sessionStorage.getItem("chainId")
            ? Number(window.sessionStorage.getItem("chainId"))
            : BinanceChainId
        );
      }

      if (error.code === 4902) {
        addNetworkHandler(NetworkDetails);
      }
    }
  };
  const addNetworkHandler = async (data) => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: data.chainId_hex,
            chainName: data.network_name,
            nativeCurrency: {
              name: data.name,
              symbol: data.symbol,
              decimals: 18,
            },
            rpcUrls: [data.url],
            blockExplorerUrls: [data.block_url],
          },
        ],
      });
    } catch (error) {
      console.log("ERROR", error);
      // toast.warn(error.message);
    }
  };
  useEffect(() => {
    setAccount(changeAccout);
  }, [changeAccout]);
  useEffect(() => {
    if (chainId !== null && changeAccout) {
      const activeChain = !isSwaped ? FieroChainId : selectedChain;
      if (parseInt(chainId) != activeChain) {
        swichNetworkHandler(activeChain);
      }
    }
  }, [chainId, changeAccout, isSwaped, selectedChain]);

  useEffect(() => {
    if (errMsg.text !== "") {
      setTimeout(() => {
        setErrMsg({
          text: "",
          color: "",
        });
      }, 5000);
    }
  }, [errMsg]);
  const [tranasctionData, setTransactionData] = useState([]);
  const getHistoryData = async () => {
    try {
      const response = await axios({
        method: "GET",
        url: `${apiURL}/list`,
        params: {
          userAddress: accountAdd,
        },
      });
      if (response) {
        setTransactionData(response.data.resultMessage);
      } else {
        setTransactionData([]);
      }
    } catch (error) {
      console.log(error);
      setTransactionData([]);
    }
  };
  useEffect(() => {
    getHistoryData();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Toaster
        position="bottom-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        // theme="light"
        theme={theme.palette.type}
      />
      <div className={classes.root1}>
        <Header
          account={accountAdd}
          connect={connect}
          chainId={chainId}
          isSwaped={isSwaped}
          setSelectedChain={(item) => setSelectedChain(item)}
          mobileView={mobileView}
          drawerOpen={drawerOpen}
          setState={(item) => setState(item)}
          tabView={tabView}
          tranasctionData={tranasctionData}
          setTabView={(item) => setTabView(item)}
        />
        {!mobileView && (
          <NavBar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
            tabView={tabView}
            setTabView={(item) => setTabView(item)}
          />
        )}

        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content} id="main-scroll">
              {errMsg.text !== "" && (
                <CustomTaost text={errMsg.text} color={errMsg.color} />
              )}
              {tabView == "Bridge" && (
                <Home
                  errMsg={errMsg}
                  chainId={chainId}
                  isSwaped={isSwaped}
                  account={accountAdd}
                  selectedChain={selectedChain}
                  connect={connect}
                  callBack={getHistoryData}
                  setErrMsg={(item) => setErrMsg(item)}
                  setIsSwaped={(item) => setIsSwaped(item)}
                  setSelectedChain={(item) => setSelectedChain(item)}
                />
              )}
              {tabView === "Support" && <Support />}

              <div className="colorShade"></div>
              <div className="colorShade"></div>
              <CookieConsent
                enableDeclineButton
                buttonStyle={{
                  background:
                    "linear-gradient(93.14deg, rgb(255, 176, 0) -20.75%, rgb(255, 86, 77) 11.84%, #ffb000 53.76%, #ffb000 102.96%)",
                  color: "#fff",
                }}
                overlay={true}
                location="bottom"
                declineButtonText="Reject"
                declineButtonClasses="declineButton"
                onAccept={() =>
                  window.localStorage.setItem("cokkiesPermisson", true)
                }
                onDecline={() =>
                  window.localStorage.setItem("cokkiesPermisson", false)
                }
              >
                <Typography variant="body2">
                  This website uses cookies in order to offer you the most
                  relevant information. Please accept cookies for optimal
                  performance.
                </Typography>
              </CookieConsent>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </ThemeProvider>
  );
}

export default App;
